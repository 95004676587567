import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAuth } from './AuthContext.v3'; // Import useAuth to get the token

const UpdateFoodPrice = () => {
  const [menuTypes, setMenuTypes] = useState([]);
  const [selectedMenuType, setSelectedMenuType] = useState('');
  const [products, setProducts] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState('');
  const [newPrice, setNewPrice] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false); // New state for loading products
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const { token } = useAuth(); // Get the token from AuthContext

  // Fetch menu types
  useEffect(() => {
    const fetchMenuTypes = async () => {
      try {
        const response = await fetch('https://api.reshipesbakery.com/reshipes/getMenuTypes', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch menu types');
        }
        const data = await response.json();
        setMenuTypes(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchMenuTypes();
  }, [token]);

  // Fetch products for selected menu type
  const fetchProducts = async (menuType) => {
    setLoadingProducts(true); // Start loading
    try {
      const response = await fetch(`https://api.reshipesbakery.com/item/getItemsWithSpecificMenu?menuName=${menuType}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch products');
      }
      const data = await response.json();
      setProducts(data);
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoadingProducts(false); // Stop loading
    }
  };

  const handleMenuTypeChange = (e) => {
    const menuType = e.target.value;
    setSelectedMenuType(menuType);
    setProducts([]); // Clear products when changing menu type
    setSelectedItemId(''); // Clear selected item when changing menu type

    if (menuType) {
      fetchProducts(menuType); // Fetch products for the selected menu type
    }
  };

  const handleUpdate = async () => {
    if (selectedItemId && newPrice) {
      setLoading(true);
      setError(null);
      setSuccess(false);

      try {
        const response = await fetch(`https://api.reshipesbakery.com/item/updatePrice?itemId=${selectedItemId}&newPrice=${newPrice}`, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${token}`, // Include the token in the authorization header
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to update price');
        }

        // Update the products state to reflect the updated price
        setProducts(products.map(product =>
          product.id === parseInt(selectedItemId) ? { ...product, price: parseFloat(newPrice) } : product
        ));
        setSuccess(true);
        alert('Price updated successfully');
      } catch (error) {
        console.error('Error updating price:', error);
        setError('Failed to update price');
        alert('Failed to update price');
      } finally {
        setLoading(false);
      }
    } else {
      alert('Please select an item and enter a new price');
    }
  };

  return (
    <div className="container mt-5">
      <h1 className="mb-4">Update Food Price</h1>
      <div className="form-group">
        <label htmlFor="menuTypeSelect">Select Menu Type:</label>
        <select
          className="form-control"
          id="menuTypeSelect"
          value={selectedMenuType}
          onChange={handleMenuTypeChange}
        >
          <option value="">Select a menu type</option>
          {menuTypes.map((menuType, index) => (
            <option key={index} value={menuType}>
              {menuType}
            </option>
          ))}
        </select>
      </div>

      {loadingProducts ? ( // Use loadingProducts to show loading message
        <p>Loading products...</p>
      ) : (
        products.length > 0 && ( // Only display the form if there are products
          <div className="form-group">
            <label htmlFor="productSelect">Select an item to update price:</label>
            <select
              className="form-control"
              id="productSelect"
              value={selectedItemId}
              onChange={e => setSelectedItemId(e.target.value)}
            >
              <option value="">Select an item</option>
              {products.map(product => (
                <option key={product.id} value={product.id}>
                  {product.size} {product.foodName} (${product.price.toFixed(2)})
                </option>
              ))}
            </select>
            <label htmlFor="newPrice" className="mt-3">New Price:</label>
            <input
              type="number"
              className="form-control"
              id="newPrice"
              value={newPrice}
              onChange={e => setNewPrice(e.target.value)}
              step="0.01"
              min="0"
            />
            <button className="btn btn-primary mt-3" onClick={handleUpdate} disabled={loading}>
              {loading ? 'Updating...' : 'Update Price'}
            </button>
          </div>
        )
      )}
      {error && <div className="mt-3 alert alert-danger">{error}</div>}
      {success && <div className="mt-3 alert alert-success">Price updated successfully!</div>}
    </div>
  );
};

export default UpdateFoodPrice;
