import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const FormClosed = () => {
  return (
    <div className="container mt-5">
      <div className="text-center">
        <img
          src="https://reshipes-logo.s3.amazonaws.com/reshipesLogo.jpg"
          alt="Reshipes Logo"
          className="form-logo"
        />
        <h1 className="mb-4">Order Form Closed</h1>
        <p>Sorry, Reshipes is no longer taking orders.</p>
      </div>
    </div>
  );
};

export default FormClosed;
