import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext.v3';
import 'bootstrap/dist/css/bootstrap.min.css';

const NudgeCustomers = () => {
  const [customers, setCustomers] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const { token } = useAuth();

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await fetch('https://api.reshipesbakery.com/reshipes/getCustomersToNudge', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch customers');
        }
        const data = await response.json();
        setCustomers(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchCustomers();
  }, [token]);

  const handleCheckboxChange = (customerName) => {
    setSelectedCustomers((prevSelected) => {
      if (prevSelected.includes(customerName)) {
        return prevSelected.filter((name) => name !== customerName);
      } else {
        return [...prevSelected, customerName];
      }
    });
  };

  const handleNudge = async () => {
    try {
      const response = await fetch('https://api.reshipesbakery.com/reshipes/nudgeCustomers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(selectedCustomers),
      });

      if (!response.ok) {
        throw new Error('Failed to nudge customers');
      }

      setSuccess(true);
      setError(null);
    } catch (error) {
      setError(error.message);
      setSuccess(false);
    }
  };

  return (
    <div className="container mt-5">
      <h1>Nudge Customers</h1>
      {error && <div className="alert alert-danger">{error}</div>}
      {success && <div className="alert alert-success alert-dismissible fade show" role="alert">
        Customers nudged successfully!
        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>}
      <div className="form-group">
        {customers.map((customer, index) => (
          <div className="form-check" key={index}>
            <input
              className="form-check-input"
              type="checkbox"
              id={`customer-${index}`}
              value={customer.name}
              onChange={() => handleCheckboxChange(customer.name)}
            />
            <label className="form-check-label" htmlFor={`customer-${index}`}>
              {customer.name}
            </label>
          </div>
        ))}
      </div>
      <button className="btn btn-primary" onClick={handleNudge}>
        Nudge Customers
      </button>
    </div>
  );
};

export default NudgeCustomers;
