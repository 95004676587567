import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const OrderFailed = () => {
  return (
    <div className="container mt-5">
      <div className="text-center">
        <img
          src="./WhatsApp Image 2024-06-07 at 13.35.08.jpeg"
          alt="Reshipes Logo"
          className="form-logo"
        />
        <h1 className="mb-4">Error Submitting</h1>
        <p>Error, your order is not able to be proccessed. Please contact Reshi at (201)906-7541</p>
      </div>
    </div>
  );
};

export default OrderFailed;
