import React from 'react';
import { useLocation } from 'react-router-dom';

const OrderSummaryPage = () => {
  const location = useLocation();
  const { order, products, total, deliveryFee } = location.state;

  return (
    <div className="container mt-5">
      <div className="mt-4 p-4 bg-light rounded shadow-sm">
      <img
          src="https://reshipes-logo.s3.amazonaws.com/reshipesLogo.jpg"
          alt="Reshipes Logo"
          className="form-logo"
        />
        <h3>Thank you for placing an order!</h3>
        <h7>Venmo (@Bryann-isaacs) and Zelle (Bryann isaacs) are the accepted forms of payment</h7>
        <h4>Final Order Summary</h4>
        {order.map(item => {
          const product = products.find(p => p.id === item.id);
          return (
            <p key={item.id}>
              {product.size} {product.foodName} x {item.quantity} = ${(product.price * item.quantity).toFixed(2)}
            </p>
          );
        })}
        {deliveryFee > 0 && (
          <p>
            <>Delivery Fee = </>${deliveryFee}
          </p>
        )}
        <p>
          <strong>Total: </strong>${total}
        </p>
      </div>
    </div>
  );
};

export default OrderSummaryPage;
