import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext.v3';
import 'bootstrap/dist/css/bootstrap.min.css';

const CloseOrdersForWeek = () => {
  const [error, setError] = useState(null);
  const { token } = useAuth();

  // Function to close orders
  const handleCloseOrders = async () => {
    try {
      const response = await fetch('https://api.reshipesbakery.com/reshipes/turnOffOrders', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      console.log(token)

      if (!response.ok) {
        throw new Error('Failed to close orders for the week');
      }

      // Optionally handle success, e.g., show a success message or navigate to another page
      alert('Orders for the week have been successfully closed.');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="container mt-5">
      <h1>Close Orders for the Week</h1>
      {error && <div className="alert alert-danger">{error}</div>}
      <button className="btn btn-danger mt-3" onClick={handleCloseOrders}>
        Close the Order Form
      </button>
    </div>
  );
};

export default CloseOrdersForWeek;
