import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext.v3';
import 'bootstrap/dist/css/bootstrap.min.css';

const OpenOrderForm = () => {
  const [menuTypes, setMenuTypes] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState('');
  const [error, setError] = useState(null);
  const { token } = useAuth();

  // Function to fetch menu types
  useEffect(() => {
    const fetchMenuTypes = async () => {
      try {
        const response = await fetch('https://api.reshipesbakery.com/reshipes/getMenuTypes', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch menu types');
        }
        const data = await response.json();
        setMenuTypes(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchMenuTypes();
  }, [token]);

  // Function to open the order form with the selected menu
  const handleMenuSelection = async (menuType) => {
    try {
      const response = await fetch(`https://api.reshipesbakery.com/reshipes/turnOnOrders?menuType=${menuType}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to open the order form');
      }

      // Optionally handle success, e.g., show a success message or navigate to another page
      alert(`Order form for ${menuType} has been successfully opened.`);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="container mt-5">
      <h1>Select Menu Type to Open Order Form</h1>
      {error && <div className="alert alert-danger">{error}</div>}
      {menuTypes.length === 0 ? (
        <p>Loading menu types...</p>
      ) : (
        <div className="list-group">
          {menuTypes.map((menuType) => (
            <button
              key={menuType}
              className={`list-group-item list-group-item-action ${selectedMenu === menuType ? 'active' : ''}`}
              onClick={() => {
                setSelectedMenu(menuType);
                handleMenuSelection(menuType);
              }}
            >
              {menuType}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default OpenOrderForm;
