import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthContext.v3';

const CustomerInfo = () => {
  const [customerInfo, setCustomerInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { token } = useAuth();

  useEffect(() => {
    const fetchCustomerInfo = async () => {
      try {
        const response = await fetch('https://api.reshipesbakery.com/reshipes/getThisWeeksCustomerInformation', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setCustomerInfo(data);
        setLoading(false);

        // Log the fetched data
        console.log('Fetched Customer Info:', data);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchCustomerInfo();
  }, [token]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!customerInfo) {
    return <div>No customer information available</div>;
  }

  return (
    <div className="container mt-5">
      <h1>This Week's Customer Information</h1>
      <div className="mt-4">
        {customerInfo.map((info, index) => (
          <div key={index} className="mt-4">
            <h3>Customer Name: {info.name}</h3>
            <p>Address: {info.streetAddress} {info.city}, {info.state} {info.zipCode} </p>
            <p>Phone Number: {info.phoneNumber}</p>
            <p>Email: {info.email}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomerInfo;
