import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext.v3';
import 'bootstrap/dist/css/bootstrap.min.css';

const EditOrder = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [orders, setOrders] = useState([]);
  const [items, setItems] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [selectedItemID, setSelectedItemID] = useState('');
  const [action, setAction] = useState('');
  const [quantity, setQuantity] = useState('');
  const [orderId, setOrderId] = useState('12345'); // Default order number
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const { token } = useAuth();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch('https://api.reshipesbakery.com/reshipes/getUsersToUpdate', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch users');
        }
        const data = await response.json();
        setUsers(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchUsers();
  }, [token]);

  const fetchOrders = async (customerName) => {
    try {
      const response = await fetch(`https://api.reshipesbakery.com/reshipes/getSpecificCustomerOrder?customerName=${customerName}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch orders');
      }
      const data = await response.json();
      setOrders(data);
      if (data.length > 0) {
        setOrderId(data[0].orderId); // assuming orderId is the same for all items in an order
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const fetchItems = async () => {
    try {
      const response = await fetch('https://api.reshipesbakery.com/item/getItemsWithSpecificMenu', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch items');
      }
      const data = await response.json();
      setItems(data);
    } catch (error) {
      setError(error.message);
    }
  };

  const fetchOrderItems = async (customerName) => {
    try {
      const response = await fetch(`https://api.reshipesbakery.com/reshipes/getSpecificCustomerOrder?customerName=${customerName}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch order items');
      }
      const data = await response.json();
      setOrderItems(data);
      console.log(data);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleAction = async () => {
    const urlMap = {
      add: 'https://api.reshipesbakery.com/reshipes/addToUserOrder',
      delete: 'https://api.reshipesbakery.com/reshipes/deleteFromUserOrder',
      update: 'https://api.reshipesbakery.com/reshipes/updateUserOrder',
    };

    const methodMap = {
      add: 'POST',
      delete: 'DELETE',
      update: 'PUT',
    };

    const body = {
      orderItem: {
        itemID: parseInt(selectedItemID), // Use the selectedItemID here
        quantity: parseInt(quantity),
      },
      orderId: parseInt(orderId),
    };

    try {
      const response = await fetch(urlMap[action], {
        method: methodMap[action],
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error(`Failed to ${action} order`);
      }

      setSuccess(true);

      // Refetch data to update the UI
      if (action === 'add') {
        await fetchOrderItems(selectedUser); // Refresh order items after adding
      } else if (action === 'delete' || action === 'update') {
        await fetchOrderItems(selectedUser); // Refresh order items after deleting or updating
      }
      setItems([]); // Clear the items list if the action was add
      setSelectedItemID(''); // Clear selected item
      setQuantity(''); // Clear quantity input
    } catch (error) {
      setError(error.message);
    }
  };

  const handleActionChange = (action) => {
    setAction(action);
    if (action === 'add') {
      fetchItems();
    } else if (action === 'delete' || action === 'update') {
      fetchOrderItems(selectedUser);
    }
  };

  return (
    <div className="container mt-5">
      <h1>Edit Orders</h1>
      {error && <div className="alert alert-danger">{error}</div>}
      {success && <div className="alert alert-success">Action successful!</div>}
      <div className="form-group">
        <label htmlFor="users">Select User</label>
        <select
          className="form-control"
          id="users"
          value={selectedUser}
          onChange={(e) => {
            setSelectedUser(e.target.value);
            fetchOrders(e.target.value);
          }}
        >
          <option value="">Select User</option>
          {users.map((user, index) => (
            <option key={index} value={user}>
              {user}
            </option>
          ))}
        </select>
      </div>
      {orders.length > 0 && (
        <>
          <div className="form-group">
            <label htmlFor="action">Select Action</label>
            <select
              className="form-control"
              id="action"
              value={action}
              onChange={(e) => handleActionChange(e.target.value)}
            >
              <option value="">Select Action</option>
              <option value="add">Add to Order</option>
              <option value="delete">Delete from Order</option>
              <option value="update">Update Order</option>
            </select>
          </div>
          {action === 'add' && items.length > 0 && (
            <>
              <div className="form-group">
                <label htmlFor="items">Select Item to Add</label>
                <select
                  className="form-control"
                  id="items"
                  value={selectedItemID}
                  onChange={(e) => setSelectedItemID(e.target.value)}
                >
                  <option value="">Select an item</option>
                  {items.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.size} {item.foodName} (${item.price.toFixed(2)})
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="quantity">Quantity</label>
                <input
                  type="number"
                  className="form-control"
                  id="quantity"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  required
                />
              </div>
              <button className="btn btn-primary" onClick={handleAction}>
                Add Item
              </button>
            </>
          )}
          {action === 'delete' && orderItems.length > 0 && (
            <>
              <div className="form-group">
                <label htmlFor="orderItems">Select Item to Delete</label>
                <select
                  className="form-control"
                  id="orderItems"
                  value={selectedItemID}
                  onChange={(e) => setSelectedItemID(e.target.value)}
                >
                  <option value="">Select an item</option>
                  {orderItems.map((item) => (
                    <option key={item.itemId} value={item.itemId}>
                      {item.size} {item.foodName} (Quantity: {item.quantity}) 
                    </option>
                  ))}
                </select>
              </div>
              <button className="btn btn-primary" onClick={handleAction}>
                Delete Item
              </button>
            </>
          )}
          {action === 'update' && orderItems.length > 0 && (
            <>
              <div className="form-group">
                <label htmlFor="orderItems">Select Item to Update</label>
                <select
                  className="form-control"
                  id="orderItems"
                  value={selectedItemID}
                  onChange={(e) => {
                    const selected = orderItems.find(item => item.itemID === parseInt(e.target.value));
                    setSelectedItemID(e.target.value);
                    if (selected) {
                      setQuantity(selected.quantity);
                    } else {
                      setQuantity('');
                    }
                  }}
                >
                  <option value="">Select an item</option>
                  {orderItems.map((item) => (
                    <option key={item.itemId} value={item.itemId}>
                      {item.size} {item.foodName} (Quantity: {item.quantity})
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="quantity">Quantity</label>
                <input
                  type="number"
                  className="form-control"
                  id="quantity"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  required
                />
              </div>
              <button className="btn btn-primary" onClick={handleAction}>
                Update Item
              </button>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default EditOrder;
