// src/components/OptionsPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const OptionsPage = () => {
  const navigate = useNavigate();

  return (
    <div className="container mt-5">
      <h1 className="mb-4"> Admin Options</h1>
      <div className="list-group">
        <button className="list-group-item list-group-item-action" onClick={() => navigate('/reshipes/this-weeks-orders')}>
          Show This Week's Orders
        </button>
        <button className="list-group-item list-group-item-action" onClick={() => navigate('/reshipes/product-quantity-needed')}>
          Show How Much of Each Product I Need
        </button>
        <button className="list-group-item list-group-item-action" onClick={() => navigate('/reshipes/delivery-addresses')}>
          Get The Delivery Addresses
        </button>
        <button className="list-group-item list-group-item-action" onClick={() => navigate('/reshipes/customer-info')}>
          Get Customers Information (phone number, email, etc)
        </button>
        <button className="list-group-item list-group-item-action" onClick={() => navigate('/reshipes/nudge-customers')}>
          Nudge Customers to Pay
        </button>
        <button className="list-group-item list-group-item-action" onClick={() => navigate('/reshipes/add-items')}>
          Add Items To The Menu
        </button>
        <button className="list-group-item list-group-item-action" onClick={() => navigate('/reshipes/delete-items')}>
          Delete Items From The Menu
        </button>
        <button className="list-group-item list-group-item-action" onClick={() => navigate('/reshipes/update-price')}>
          Update Prices On The Menu
        </button>
        <button className="list-group-item list-group-item-action" onClick={() => navigate('/reshipes/display-menu')}>
          Display Specific Menu
        </button>
        <button className="list-group-item list-group-item-action" onClick={() => navigate('/reshipes/open-orders')}>
          Open Order form
        </button>
        <button className="list-group-item list-group-item-action" onClick={() => navigate('/reshipes/close-orders')}>
          Close Order form
        </button>
        <button className="list-group-item list-group-item-action" onClick={() => navigate('/reshipes/edit-order')}>
          Edit A Customers Order
        </button>
        <button className="list-group-item list-group-item-action" onClick={() => navigate('/reshipes/all-orders')}>
          Display All Orders Ever Placed 
        </button>
      </div>
    </div>
  );
};

export default OptionsPage;
