import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthContext.v3';

const GetDeliveryAddresses = () => {
  const [addresses, setAddresses] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { token } = useAuth();

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const response = await fetch('https://api.reshipesbakery.com/reshipes/getThisWeeksAddresses', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setAddresses(data);
        setLoading(false);

        // Log the fetched data
        console.log('Fetched Addresses:', data);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchAddresses();
  }, [token]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!addresses || addresses.length === 0) {
    return <div>No addresses available</div>;
  }

  return (
    <div className="container mt-5">
      <h1>This Week's Delivery Addresses</h1>
      <div className="mt-4">
        {addresses.map((address, index) => (
          <div key={index} className="mt-4">
            <h3>Customer Name: {address.customerName}</h3>
            <p>Address: {address.streetAddress}</p>
            <p>City: {address.city}</p>
            <p>State: {address.state}</p>
            <p>Zip Code: {address.zipCode}</p>
            <p>Apt Number: {address.aptNumber}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GetDeliveryAddresses;
