import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthContext.v3';
import './WeeksOrders.css';

const ThisWeeksOrders = () => {
  const [weeklyOrders, setWeeklyOrders] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { token } = useAuth();

  // Fetch products data
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('https://api.reshipesbakery.com/item/getAllItems');
        if (!response.ok) {
          throw new Error('Failed to fetch products');
        }
        const prodData = await response.json();
        setProducts(prodData);
        console.log("hi");
        console.log(prodData); 
      }
      catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  // Fetch weekly orders
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch('https://api.reshipesbakery.com/reshipes/getAllData', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setWeeklyOrders(data);
        setLoading(false);
        console.log(data);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchOrders();
  }, [token]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!weeklyOrders) {
    return <div>No orders available</div>;
  }

  // Group orders by orderId
  const groupedOrders = weeklyOrders.orders.reduce((acc, order) => {
    if (!acc[order.orderId]) {
      acc[order.orderId] = {
        orderId: order.orderId,
        customerName: order.customerName,
        orderDate: order.orderDate,
        orderTime: order.orderTime,
        comments: order.comments,
        inNJ: order.inNJ, // Add inNJ field
        items: []
      };
    }
    acc[order.orderId].items.push({
      itemId: order.itemId,
      foodName: order.foodName,
      quantity: order.quantity,
      size: order.size
    });
    return acc;
  }, {});

  // Convert grouped orders object into an array for mapping
  const ordersArray = Object.values(groupedOrders);

  // Function to convert date and time to a Date object
  const createDateTimeObject = (order) => {
    return new Date(`${order.orderDate}T${order.orderTime}`);
  };

  // Sort orders by date and time, newest first
  ordersArray.sort((a, b) => createDateTimeObject(b) - createDateTimeObject(a));

  const calculateOrderTotal = (order) => {
    const itemsTotal = order.items.reduce((total, item) => {
      const product = products.find(p => p.id === item.itemId);
      return total + (product ? product.price * item.quantity : 0);
    }, 0);

    // Add delivery fee if inNJ is false
    const deliveryFee = !order.inNJ ? 5 : 0;

    return (itemsTotal + deliveryFee).toFixed(2);
  };

  return (
    <div className="container mt-5">
      <h1>All Orders Placed</h1>
      <div className="mt-4">
        <h4>Total Profit: ${ordersArray.reduce((acc, order) => acc + parseFloat(calculateOrderTotal(order)), 0).toFixed(2)}</h4>
      </div>
      {ordersArray.map(order => (
        <div key={order.orderId} className="mt-4">
          <h3>Customer Name: {order.customerName}</h3>
          <p>Order Date: {order.orderDate}</p>
          <p>Order Time: {order.orderTime}</p>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Food Name</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {order.items.map((item, index) => (
                <tr key={index}>
                  <td>{item.size} {item.foodName}</td>
                  <td>{item.quantity}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <p>Comments: {order.comments}</p>
          {!order.inNJ && <p>Delivery Fee: $5.00</p>} {/* Show delivery fee if inNJ is false */}
          <h4>Total Amount: ${calculateOrderTotal(order)}</h4>
        </div>
      ))}
    </div>
  );
};

export default ThisWeeksOrders;
