import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthContext.v3';

const ProductQuantityNeeded = () => {
  const [productQuantities, setProductQuantities] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]); // State for categories
  const { token } = useAuth();

  // Fetch categories
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch('https://api.reshipesbakery.com/reshipes/getCategoryTypes', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch categories');
        }

        const data = await response.json();
        setCategories(data); // Set the categories state with the fetched data
      } catch (error) {
        setError(error);
      }
    };

    fetchCategories();
  }, [token]);

  // Fetch product quantities
  useEffect(() => {
    const fetchProductQuantities = async () => {
      try {
        const response = await fetch('https://api.reshipesbakery.com/reshipes/getThisWeeksFoodTotals', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();

        // Log the data to the console
        console.log('Fetched product quantities:', data);

        setProductQuantities(data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchProductQuantities();
  }, [token]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!productQuantities || !categories.length) {
    return <div>No product quantities available</div>;
  }

  // Group products by category
  const groupedByCategory = productQuantities.reduce((groups, product) => {
    const category = product.category || 'Uncategorized';
    if (!groups[category]) {
      groups[category] = [];
    }
    groups[category].push(product);
    return groups;
  }, {});

  // Sort categories by the fetched order and append others at the end
  const sortedCategories = [
    ...categories.filter(category => groupedByCategory[category]), // Categories in the fetched order
    ...Object.keys(groupedByCategory).filter(category => !categories.includes(category)) // Remaining categories
  ];

  return (
    <div className="container mt-5">
      <h1>Product Quantities Needed For This Week</h1>
      {sortedCategories.map((category, index) => (
        <div key={index} className="mt-4">
          <h4>{category}</h4>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Quantity Needed</th>
              </tr>
            </thead>
            <tbody>
              {groupedByCategory[category].map((product, idx) => (
                <tr key={idx}>
                  <td>{product.size} {product.foodName}</td>
                  <td>{product.totalQuantity}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default ProductQuantityNeeded;
