import { useAuth } from '../components/AuthContext.v3';

const useFetchWithAuth = (url, options = {}) => {
  const { token } = useAuth();

  const fetchWithAuth = async () => {
    const response = await fetch(url, {
      ...options,
      headers: {
        ...options.headers,
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return response.json();
  };

  return fetchWithAuth;
};

export default useFetchWithAuth;
