import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAuth } from './AuthContext.v3';

const DeleteFoodItem = () => {
  const [menuTypes, setMenuTypes] = useState([]);
  const [selectedMenuType, setSelectedMenuType] = useState('');
  const [products, setProducts] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const { token } = useAuth(); // Get the token from AuthContext

  // Fetch menu types
  useEffect(() => {
    const fetchMenuTypes = async () => {
      try {
        const response = await fetch('https://api.reshipesbakery.com/reshipes/getMenuTypes', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch menu types');
        }
        const data = await response.json();
        setMenuTypes(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchMenuTypes();
  }, [token]);


  // Fetch products for selected menu type
  const fetchProducts = async (menuType) => {
    try {
      const response = await fetch(`https://api.reshipesbakery.com/item/getItemsWithSpecificMenu?menuName=${menuType}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch products');
      }
      const data = await response.json();
      setProducts(data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleMenuTypeChange = (e) => {
    const menuType = e.target.value;
    setSelectedMenuType(menuType);
    setProducts([]); // Clear products when changing menu type
    setSelectedItemId(''); // Clear selected item when changing menu type

    if (menuType) {
      fetchProducts(menuType); // Fetch products for the selected menu type
    }
  };

  const handleDelete = async () => {
    if (selectedItemId) {
      setLoading(true);
      setError(null);
      setSuccess(false);

      try {
        const response = await fetch(`https://api.reshipesbakery.com/item/removeItem?itemId=${selectedItemId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`, // Include the token in the authorization header
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to delete item');
        }

        // Refetch the updated list of products after deletion
        await fetchProducts(selectedMenuType);
        setSuccess(true);
        alert('Item deleted successfully');
      } catch (error) {
        console.error('Error deleting item:', error);
        setError('Failed to delete item');
        alert('Failed to delete item');
      } finally {
        setLoading(false);
        setSelectedItemId(''); // Reset the selected item after deletion
      }
    } else {
      alert('Please select an item to delete');
    }
  };

  return (
    <div className="container mt-5">
      <h1 className="mb-4">Delete Food Item</h1>

      <div className="form-group">
        <label htmlFor="menuTypeSelect">Select Menu Type:</label>
        <select
          className="form-control"
          id="menuTypeSelect"
          value={selectedMenuType}
          onChange={handleMenuTypeChange}
        >
          <option value="">Select a menu type</option>
          {menuTypes.map((menuType, index) => (
            <option key={index} value={menuType}>
              {menuType}
            </option>
          ))}
        </select>
      </div>

      {selectedMenuType && products.length === 0 ? (
        <p>Loading products...</p>
      ) : (
        selectedMenuType && (
          <div className="form-group mt-3">
            <label htmlFor="productSelect">Select an item to delete:</label>
            <select
              className="form-control"
              id="productSelect"
              value={selectedItemId}
              onChange={e => setSelectedItemId(e.target.value)}
            >
              <option value="">Select an item</option>
              {products.map(product => (
                <option key={product.id} value={product.id}>
                  {product.size} {product.foodName} (${product.price})
                </option>
              ))}
            </select>
            <button className="btn btn-danger mt-3" onClick={handleDelete} disabled={loading}>
              {loading ? 'Deleting...' : 'Delete Item'}
            </button>
          </div>
        )
      )}

      {error && <div className="mt-3 alert alert-danger">{error}</div>}
      {success && <div className="mt-3 alert alert-success">Item deleted successfully!</div>}
    </div>
  );
};

export default DeleteFoodItem;
